<template>
  <base-section
    id="map"
    space="0"
  >
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3160.4017557814363!2d126.7146073152955!3d37.6162364797887!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c840ac91f1ab5%3A0xed2ec36161995383!2z6rK96riw64-EIOq5gO2PrOyLnCDsgqzsmrDspJHroZwgMTA!5e0!3m2!1sko!2skr!4v1663922414208!5m2!1sko!2skr"
      width="100%"
      height="500"
      style="border:0;"
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    />
  </base-section>
</template>

<script>
  export default {
    name: 'SectionMap',

    provide: {
      heading: { align: 'center' },
    },
  }
</script>
